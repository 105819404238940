import React, { useEffect, useState } from 'react';
import Nav from './Nav';

function PlayIT() {
  return (
    <div className='container'>
      {/* <Nav /> */}
      <h1>Vieni a Provare Kynua's Stone!</h1>
      <p>Tutti i mercoledì sera dalle 21 presso la sede dell'associazione ludica <b>un PO di giochi</b> è possibile giocare a Kynua's Stone o semplicemente passare una serata giocando insieme</p>
      <img className='imagine' style={{marginBottom:"20px"}} src="images/logoUPG.jpg" alt="Kynua Stone" />
      <div style={{marginBottom:"10px"}}>
      <img style={{ width: '30px', height: '30px', marginBlockStart:'0px', }} src={`images/logInsta.png`} alt={"insta"} />
        <a href="https://www.instagram.com/unpodigiochi/" target="_blank" rel="noopener noreferrer"> un Po di Giochi</a>
      </div>
      <div style={{marginBottom:"10px"}}>
      <img style={{ width: '30px', height: '30px', marginBlockStart:'0px' }} src={`images/logoMaps.png`} alt={"maps"} />
        <a href="https://www.google.com/maps/place/Via+Mazzini+Giuseppe,+1,+46030+Correggioverde+MN/@44.9534778,10.578677,13z/data=!4m6!3m5!1s0x478025165656a353:0x61610cdcd84fe3b5!8m2!3d44.9296188!4d10.6174506!16s%2Fg%2F11h60fbfhk?hl=it&entry=ttu" target="_blank" rel="noopener noreferrer"> posizione sede</a>
      </div>
    </div>
  )
}

export default PlayIT