export default ({ member, handleClose, handleChange }) => {
  return (
    <dialog id="modal-member" open>
      <article>
        <a
          href="#close"
          aria-label="Close"
          className="close"
          data-target="modal-member"
          onClick={handleClose}
        ></a>
        <h2>{member.name}
            <img
              style={{ width: '100px', height: '120px', marginLeft: '40px'}}
              src={`images/${member.slug}.png`}
              alt={member.name}
              />
            <img
              style={{ width: '500px', height: '280px', marginTop: '20px' }}
              src={`gifs/${member.slug}.gif`}
              alt={member.name}
              />
        </h2>
        <div>
          <h6></h6>
            {member.bio}
            
          <div style={{
            display: "flex",
            gap: "1rem",
            marginTop: "1rem",
            justifyContent: "space-between"
          }}>
            <a
              className="outline"
              href="#"
              role="button"
              onClick={() => { handleChange(Number(member.id) - 1) }}
            >
              <span className="material-symbols-outlined">arrow_back</span>
            </a>
            <a
              className="outline"
              href="#"
              role="button"
              onClick={() => { handleChange(Number(member.id) + 1) }}
            >
              <span className="material-symbols-outlined">arrow_forward</span>
            </a>
          </div>
        </div>
      </article>
    </dialog>
  );
}
