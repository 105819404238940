import React, { useEffect, useState } from 'react';
import Nav from './Nav';

function ContattiMedia() {
  return (
    <div className='container'>
      {/* <Nav /> */}
      <h1>Contatti & Media</h1>

      <div style={{marginBottom:"10px"}}>
      <img style={{ width: '40px', height: '40px', marginBlockStart:'0px', }} src={`images/Mail.png`} alt={"mail"} />
      <a href="mailto:banaplanagames@gmail.com" target="_blank" rel="noopener noreferrer"> banaplanagames@gmail.com</a>
       
      </div>
      <div style={{marginBottom:"10px"}}>
      <img style={{ width: '30px', height: '30px', marginBlockStart:'0px', }} src={`images/logInsta.png`} alt={"insta"} />
        <a href="https://www.instagram.com/banaplana_games/" target="_blank" rel="noopener noreferrer"> banaplana_games</a>
      </div>
      <div style={{marginBottom:"10px"}}>
      <img style={{ width: '30px', height: '30px', marginBlockStart:'0px' }} src={`images/logInsta.png`} alt={"memo"} />
        <a href="https://www.instagram.com/simone.andriulo/" target="_blank" rel="noopener noreferrer"> 3D artist</a>
      </div>
      
      <div style={{marginBottom:"10px"}}>
      <img style={{ width: '30px', height: '30px', marginBlockStart:'0px' }} src={`images/download-button.svg`} alt={"man"} />
        <a href="manualeKS.pdf" target="_blank"> manuale KS</a>
      </div>
     {/*
      <div style={{marginBottom:"10px"}}>
      <img style={{ width: '30px', height: '30px', marginBlockStart:'0px' }} src={`images/shopIcon.png`} alt={"shop"} />
        <a href="https://woodart-italy.com/products/kynuas-stone-game" target="_blank" rel="noopener noreferrer"> shop</a>
      </div>
  */}
    </div>
  )
}

export default ContattiMedia