import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Kynua from "./Kynua"
import Capybara from "./Capybara"
import Home from "./components/Home"
import Other from "./components/PlayIT"
import ContattiMedia from "./components/ContattiMedia"

function App() {
  // initialize a browser router
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/kynua",
      element: <Kynua />,
    },
    {
      path: "/capybara",
      element: <Capybara />,
    },
    {
      path: "/other",
      element: <Other />,
    },
    {
      path: "/contattiMedia",
      element: <ContattiMedia />,
    },
  ])

  return (
    <div>

      <RouterProvider router={router} />

    </div>
  )
}

export default App