import React, { useEffect, useState } from 'react';
import ListCast from './components/ListCast';
import Modals from './components/Modals';
import KynuaNav from './components/KynuaNav';
import "./Kynua.scss";
import Nav from './components/Nav';
function 
Kynua() {
  const [cast, setCast] = useState([]);
  let [memberInfo, setMemberInfo] = useState(null);

  async function fetchCast() {
    const response = await fetch('cast.json');
    setCast(await response.json());
  }

  useEffect(() => {
    fetchCast();
  });

  return (
    <>
      {/* <Nav /> */}
      <h1 className="container">Kynua' Stone</h1>
      <KynuaNav cast={cast} onChoice={(info) => { setMemberInfo(info) }} />
      <div className="container">
        <hgroup>
          <img className='imagine' style={{marginBottom:"20px"}} src="images/stone_concept.png" alt="Kynua Stone" />
          <p>In <b>Kynua's Stone</b> un misterioso meteorite si è schiantato in mezzo ad un piccolo gruppo di dinosauri. Districati tra tessere rotanti, corsi d'acqua e blocchi lanciando i dadi e sfruttando l'influenza della <b>Stone</b> a tuo vantaggio per ostacolare gli avversari e far arrivare per primo il tuo t-rex.</p>
          <ListCast cast={cast} onChoice={(info) => { setMemberInfo(info) }} />
          {memberInfo && <Modals member={memberInfo} handleChange={(info) => { setMemberInfo(cast[info]) }} handleClose={() => { setMemberInfo(null) }} />}
        </hgroup>

      </div>
    </>
  )
}

export default Kynua;
