import React, { useEffect, useState } from 'react';
import Nav from './Nav';
import Kynua from '../Kynua';
import Capybara from '../Capybara'
import PlayIT from './PlayIT';
import ContattiMedia from './ContattiMedia';

function Home() {
  return (
    <div className='container'>
      <Nav />
      <h1>Banaplana games</h1>
      Siamo un gruppo di game designer desiderosi di sfruttare al massimo le potenzialità della modellazione e stampa 3D applicate ai giochi da tavolo.
      <div>
      <img
              style={{ width: '600px', height: '300px', marginBlockStart:'20px' }}
              src={`images/ksComponents.jpg`}
              alt={"ciao"}
            />
      </div>
      <Kynua />
      <br />
      <Capybara />
      <br />
      <PlayIT />
      <br />
      <ContattiMedia />
    </div>


  )
}

export default Home


