import "./Nav.scss";

export default ({ cast, onChoice }) => {
  return (
    <nav className="container">
      <ul>
        <li>
          <details role="list">
            <summary aria-haspopup="listbox" role="link">info</summary>
            <ul role="listbox">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/kynua">Kynua's Stone</a>
              </li>
              <li>
                <a href="/other">Contatti & Media</a>
              </li>
            </ul>
          </details>
        </li>

      </ul>
      <ul>
        <li><a href="#"><img style={{ height: '50px' }} src="images/banaPLAna.svg" alt="Banaplana Logo" /></a></li>
      </ul>
    </nav>
  )
}