import "./Nav.scss";

export default ({ capyCast, onChoice }) => {
  return (
    <nav className="container">
      <ul>

        <li>
          <details role="list">
            <summary aria-haspopup="listbox" role="link">azioni</summary>
            <ul role="listbox">
              {capyCast.map(member => (
                <li key={member.id}>
                  <a onClick={() => { onChoice(member) }}
                    data-tooltip={member.name}>{member.name}</a>
                </li>
              ))}
            </ul>
          </details>
        </li>
      </ul>

    </nav>
  )
}