import React, { useEffect, useState } from 'react';
import CapyNav from './components/CapyNav';
import ListCapyCast from './components/ListCapyCast';
import CapyModals from './components/CapyModals';
import "./Kynua.scss";
import image from './capyStuff/logo_capy.png';

function 
Kynua() {
  const [capyCast, setCast] = useState([]);
  let [memberInfo, setMemberInfo] = useState(null);

  async function fetchCapyCast() {
    const response = await fetch('capyCast.json');
    setCast(await response.json());
  }

  useEffect(() => {
    fetchCapyCast();
  });

  return (
    <>
      {/* <Nav /> */}
      <h1 className="container">Capybara Stream</h1>
      <CapyNav capyCast={capyCast} onChoice={(info) => { setMemberInfo(info) }} />
      <div className="container">
        <hgroup>
          <img className='imagine' style={{marginBottom:"20px"}} src={image} alt="Capibara" />
          <p>In <b>Capybara Stream</b> I giocatori devono radunare il branco più grande di capibara attirando quelli presenti nella riserva comune o rubandoli direttamente agli avversari</p>
        
          <ListCapyCast capyCast={capyCast} onChoice={(info) => { setMemberInfo(info) }} />
          {memberInfo && <CapyModals member={memberInfo} handleChange={(info) => { setMemberInfo(capyCast[info]) }} handleClose={() => { setMemberInfo(null) }} />}
        
        </hgroup>
      </div>
    </>
  )
}

export default Kynua;
